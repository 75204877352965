 
.skeletonProductDiv {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding: 16px;
    background-color: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    flex-wrap: wrap;  
  }
  
  .skeletonImage {
    width: 80px;
    height: 80px;
    background-color: #f09595;
    border-radius: 8px;
    margin-right: 16px;
  }
  
  .skeletonDetails {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .skeletonDetails > div {
    height: 16px;
    background-color: #e0e0e0;
    border-radius: 4px;
  }
  
  .skeletonPriceDiv {
    background-color: #f8f8f8;
    padding: 16px;
    border-radius: 8px;
    margin-top: 24px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .skeletonPriceDiv > div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  
  .skeletonPriceDiv > div > div {
    width: 100px;
    height: 16px;
    background-color: #e0e0e0;
    border-radius: 4px;
  }
  
  .loaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
   @media (max-width: 768px) {
    .skeletonProductDiv {
      flex-direction: column;  
      align-items: flex-start;
    }
  
    .skeletonImage {
      margin-right: 0;
      margin-bottom: 16px;
      width: 60px;
      height: 60px;
    }
  
    .skeletonDetails > div {
      height: 14px; 
    }
  
    .skeletonPriceDiv {
      padding: 12px;
    }
  
    .skeletonPriceDiv > div > div {
      width: 80px; 
      height: 14px;
    }
  }
 
  


.loaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #f5f5f5;
}

.icon {
    display: block;
    margin: auto;
}

.outerDiv {
    min-height: 100vh;
    width: 100%;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
    padding: 20px;
    box-sizing: border-box;
    overflow-x: hidden;
 }

.errorMsg {
    height: fit-content;
    /* border: 1px solid black; */
    color: #d9534f;
    font-size: 16px;
    text-align: center;
    font-family: "Poppins", sans-serif;

}

.innerDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    box-sizing: border-box;
}

.leftDiv {
    width: 60%;
    min-width: 700px;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.rightDiv {
    width: 30%;
    min-width: 300px;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.productDiv {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 10px;
    border-bottom: 1px solid #eaeaea;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

.productDiv:hover {
    transform: scale(1.02);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.productImg {
    height: 120px;
    width: 120px;
    object-fit: cover;
    border-radius: 8px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.productImg:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.productDetails {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.price,
.quantity,
.productName {
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    color: #333;
    display: flex;
    align-items: center;
      
}
.productName{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}
  
.editAndRemove {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.editBtn,
.removetBtn {
    padding: 8px 15px;
    border: none;
    border-radius: 5px;
     color: #fff;
    text-align: center;
    font-family: "Poppins", sans-serif;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}
.editBtn{
    background: linear-gradient(135deg, #051b11, #2c0f0f);
}
.removetBtn {
    background: linear-gradient(135deg, #1e1c1c, #160404);
}
.editBtn:hover,
.removetBtn:hover {
    transition: all 0.3s ease;
    transform: scale(1.05);
}

.totalPriceDiv {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.priceDetails {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
} 

.desPrice {
    margin-top: 10px;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
}

.price {
    width: 100%;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.price>p {
    font-size: 16px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
}


.pd {
    padding-top: 10px;
    display: block;
    font-size: 15px;
    font-family: "Poppins", sans-serif;
}

.checkOutDiv {
    position: relative;
    display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  animation: fadeIn 0.5s ease-in-out;
  font-size: 18px;
  font-weight: bold;
  color: white;
  transform: perspective(1000px) rotateX(10deg);

}
/* Continuous Shimmer Effect */
.checkOutBtn::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.5), transparent);
    transform: skewX(-25deg);
    animation: shimmerMove 2.5s infinite linear;
  }
  
  @keyframes shimmerMove {
    0% {
      left: -100%;
    }
    100% {
      left: 100%;
    }
  }

.checkOutBtn {
    background: linear-gradient(135deg, #07b598, #481b1b);
    border: none;
    color: white;
    font-size: 18px;
    font-weight: bold;
    padding: 12px 24px;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.3s ease;
    position: relative;
}
.checkOutBtn:hover {
    transform: perspective(1000px) rotateX(0deg) scale(1.05);
     border: 2px solid rgba(255, 255, 255, 0.6);
}

.checkOutBtn:active {
    transform: perspective(1000px) rotateX(-5deg) scale(0.95);
}

 
.disabledBtn {
    opacity: 0.5;
    cursor: not-allowed;
    box-shadow: none;
    transform: none;
    background: gray;
  }
 
.messageDiv {
    margin-top: 20px;
    padding: 10px;
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
    text-align: center;
}

.messageText {
    font-size: 14px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    letter-spacing: 1px;
    line-height: 1.5;
}
 
.spinner {
    animation: spin 1s linear infinite;
    margin-right: 8px;
    font-size: 30px;
}

/* Empty Cart Message */
.emptyCartMessage {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #f8f8f8;
    border: 1px solid #e0e0e0;
    margin: 20px 0;
    border-radius: 8px;
    font-size: 16px;
    color: #333;
    text-align: center;
}

.emptyCartMessage p {
    margin: 0;
    padding: 0;
    font-weight: bold;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
  
 



@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideDown {
    from { transform: translateY(-20px); }
    to { transform: translateY(0); }
}


@media (max-width:1024px) {
    .innerDiv {
        width: 90vw;
    }
}

@media (max-width:768px) {
    .emptyCartMessage {
        font-size: 14px;
        padding: 15px;
        border: none;
    }

    .leftDiv {
        width: 100%;
        min-width: unset;
    }
    .rightDiv {
        width: 100%;
        min-width: unset;
    }
 

    .productDiv {
        height: fit-content;
        min-height: fit-content;
        flex-wrap: wrap;
        margin: 10px 0;
        gap: 5px;
    }

    .leftDiv {
        height: fit-content;
        min-width: 99%;
        padding: 0;
    }

    .productImg {
        width: 100px;
        height: 100px;
    }

    .productDetails {
        height: 110px;
        width: 71%;
    }

    .editAndRemove {
        flex-direction: row;
        gap: 10px;
        width: 100%;
        justify-content: space-around;
    }

    .editBtn,
    .removetBtn {
         width: 40%;
 
    }

    .price,
    .quantity,
    .productName {
        height: 20%;
        width: 99%;
        font-size: 15px;
    }
    .productName {
        font-size: 14px;
    }

    .price>p {
        font-size: 14px;
        font-weight: 400;
        font-family: "Poppins", sans-serif;
    }

    .checkOutDiv {
        padding: 30px 0;
    }
    .messageText {
        font-size: 12px;
    }
    .messageDiv {
        padding: 5px;
        margin-bottom: 15px;
    }
    .buttonContent {
        flex-direction: row;
    }

}

@media (max-width:450px) {
    .emptyCartMessage {
        font-size: 12px;
        padding: 10px;
    }

    .leftDiv {
        gap:0;
    }

    .rightDiv {
        height: fit-content;
        width: 100%;
    }
    .productDiv {
        padding: 10px 5px;
    }

    .editAndRemove {
        width: 90vw;
    }

    .totalPriceDiv,
    .rightDiv {
        min-width: 100%;
    }

    .productImg {
        height: 100px;
        width: 80px;
    }
    .checkOutBtn {
        width: 100%;
    }

    .totalPriceDiv {
        padding: 15px;
    }
}