.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;  
    background-color: #f5f5f5; 
    text-align: center;
    padding: 20px;
    flex-direction: column;  
  }
  
  .content {
    max-width: 500px;
    width: 100%;
  }
  
  .logo {
    font-size: 50px;  
    margin-bottom: 20px;
    animation: logoSpin 3s infinite ease-in-out;
    color: #C7395C;  
  }
  
  @keyframes logoSpin {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(90deg);  
    }
    100% {
      transform: rotate(0deg);
    }
  }
  
  .heading {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .subtext {
    font-size: 18px;
    color: #777;
    margin-bottom: 30px;
  }
  
  .loaderWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;  
  }
  
  @media (max-width: 768px) {
    .heading {
      font-size: 20px;
    }
  
    .subtext {
      font-size: 16px;
    }
  
    .logo {
      font-size: 50px;
    }
  }
  
  @media (max-width: 480px) {
    .heading {
      font-size: 18px;
    }
  
    .subtext {
      font-size: 14px;
    }
  
    .logo {
      font-size: 40px;
    }
  }
  